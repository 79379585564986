<template>
  <div class="house-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      typeList: [], //房型列表
      facilitiesList: [], //配套列表
      opt: {
        title: "房型列表",
        columns: [
          {
            label: "房型名称",
            key: "name",
            on: row => {
              _this.$router.push({
                path: "./detail/show/" + row.id,
                query: {
                  apartmentType: row.apartmentType
                }
              })
            }
          },
          { label: "房源名称", key: "apartmentName" },
          { label: "面积（㎡）", key: "area" },
          // { label: "网费（元/月）", key: "netFee" },
          // { label: "租赁方式", key: "leasingModeStr" },
          { label: "房东名称", key: "landlordName" },
          { label: "电梯", key: "liftStr" },
          { label: "配置", key: "facilities" }
        ],
        buttons: [
          {
            type: 0,
            on() {
              _this.$router.push("./detail/add/0");
            }
          },
          {
            type: 1,
            on(data) {
              if(data.data.typeId == "99") {
                return _this.$message({
                  showClose: true,
                  message: "默认房型不支持编辑!",
                  type: "warning"
                });
              }
              _this.$router.push({
                path: "./detail/edit/" + data.id,
                query: {
                  apartmentType: data.data.apartmentType
                }
              })
            }
          },
          {
            type: 2,
            on(data) {
              if (data.ids.length > 1) {
                return _this.$message({
                  showClose: true,
                  message: "不支持多个删除,只支持单个删除!",
                  type: "warning"
                });
              }
              if(data.list[0].typeId == "99") {
                return _this.$message({
                  showClose: true,
                  message: "默认房型不支持删除!",
                  type: "warning"
                });
              }
              const h = _this.$createElement;
              _this
                .$msgbox({
                  title: "删除提示",
                  message: h("p", null, [
                    h(
                      "span",
                      {
                        style: "color: #333;font-size: 16px;margin-left:98px"
                      },
                      "确定要删除房型吗"
                    ),
                    h(
                      "p",
                      {
                        style: "font-size:14px;margin-left:98px"
                      },
                      "删除房型后，房型下的房间都将被删除"
                    )
                  ]),
                  showCancelButton: true,
                  confirmButtonText: "确定",
                  cancelButtonText: "取消"
                })
                .then(action => {
                  var dto = {
                    id: data.ids[0],
                    typeName: data.list[0].name
                  };
                  _this
                    .post("landlord-service/roomType/delete", dto)
                    .then(function() {
                      _this.$message({
                        showClose: true,
                        message: "删除房型成功！",
                        type: "success"
                      });
                      data.refresh();
                    });
                });
            }
          }
        ]
      }
    };
  },
  created() {
    console.log("house-list created!!");
    // this.getTypeIdList();
    // this.getFacilitiesList();
    // 学校商户类型不展示编辑按钮
    if(this.getSchoolLandlordFlag()) {
      this.opt.buttons.splice(1, 1);
    }
    
  },
  activated() {
    if(this.getSchoolLandlordFlag()) {
      // 不展示 面积 电梯  配置
      this.opt.columns.forEach((item,index) => {
        if(item.key == "name") {
          this.opt.columns.splice(index, 1,{
            label: "房型名称",
            key: "name",
          })
        }
        if(item.key == "area") {
          this.opt.columns.splice(index, 1);
        }
        if(item.key == "liftStr") {
          this.opt.columns.splice(index, 1);
        }
      })
      this.opt.columns.forEach((item,index) => {
        if(item.key == "facilities") {
          this.opt.columns.splice(index, 1);
        }
      })
    }
    
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNumber: opt.skip,
        pageSize: opt.limit,
        landlordId:
          this.cache.getLS("userInfo")["roleType"] == "1"
            ? null
            : this.cache.getLS("userInfo")["userId"],
        ...opt.searchForm
      };
      this.post("landlord-service/roomType/queryPagesRoomType", dto, {
        isUseResponse: true
      }).then(res => {
        Promise.all([this.getTypeIdList(), this.getFacilitiesList()]).then(
          values => {
            for (let i = 0; i < res.data.data.length; i++) {
              let item = res.data.data[i];
              // item.leasingModeStr = ["整租", "合租"][item.leasingMode - 1];
              item.elevatorStr = ["有", "无"][item.elevator - 1];
              item.liftStr = ["有", "无"][item.lift - 1];

              // 赋值房型名称 和 赋值配套名称
              let nameStr = "";
              let facilitiesName = [];

              this.typeList = values[0];
              for (let i = 0; i < this.typeList.length; i++) {
                const ele = this.typeList[i];
                if (item.typeId == ele.id) {
                  nameStr = ele.name;
                  break;
                }
              }
              
              item.name = nameStr;

              if(item.apartmentType == 7) {
                item.area = '--';
                item.liftStr = '--';
                item.facilities = '--';
              }else {
                this.facilitiesList = values[1];
                this.facilitiesList.forEach(data => {
                  let arr = item.facilities.split(",");
                  if (arr) {
                    arr.forEach(it => {
                      if (it == data.id) {
                        facilitiesName.push(data.name);
                      }
                    });
                  }
                });
                item.facilities = facilitiesName.join(", ");
              }
            }
            opt.cb(res.data);
          }
        );
      });
    },

    // 获取房型
    getTypeIdList() {
      return new Promise((resolve, reject) => {
        if (this.typeList.length) {
          resolve(this.typeList);
        } else {
          this.post("landlord-service/base/getRoomTypeAll", {}).then(res => {
            this.typeList = res;
            resolve(this.typeList);
          });
        }
      });
    },

    // 获取房型配套
    getFacilitiesList() {
      return new Promise((resolve, reject) => {
        if (this.facilitiesList.length) {
          resolve(this.facilitiesList);
        } else {
          this.post("landlord-service/base/getFacilitiesAll", {}).then(res => {
            this.facilitiesList = res;
            resolve(this.facilitiesList);
          });
        }
      });
    }
  }
};
</script>